.widget-container {
  margin-right: 320px;
}

#calendar-container {
  margin: auto;
}

.dx-calendar-cell:not(.dx-calendar-other-month) .weekend,
.dx-calendar-cell:not(.dx-calendar-other-month) .holyday {
  text-shadow: none;
  font-weight: bold;
}

.dx-calendar-cell:not(.dx-calendar-other-month) .weekend {
  color: #3030ff;
}

.dx-state-disabled.dx-calendar
  .dx-calendar-cell:not(.dx-calendar-other-month)
  .weekend {
  color: #8080ff;
}

.dx-calendar-cell:not(.dx-calendar-other-month) .holyday {
  color: #ff3030;
}

.dx-state-disabled.dx-calendar
  .dx-calendar-cell:not(.dx-calendar-other-month)
  .holyday {
  color: #ff8080;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 260px;
}

.caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 10px;
}
.comHeight {
  height: 100%;
}
.dxc-val-line {
  display: none;
}
.dxc-arg-line {
  display: none;
}
.container {
  width: 94.7%;
  height: 100%;
  overflow: auto;
}
.container::-webkit-scrollbar {
  width: 10px;
}
.container::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
}
.container::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}
.ecg-chart {
  background-repeat: repeat;
  background-image: url(../images/graph_back.png);
}
.capno-chart-arrow-left {
  position: relative;
  float: left;
  left: 55px;
  top: 120px;
  font-size: 30px;
}

.capno-chart-arrow-right {
  position: relative;
  float: right;
  right: 10px;
  top: 120px;
  font-size: 30px;
}

.chart-section {
  display: none;
}

.border-bottom-hidden {
  border-bottom: hidden;
}

.chart-slick {
  background-color: #5c2e91;
  background-color: #fff;
  color: #fff;
  color: #3498db;
  text-align: center;
}

.display-none {
  display: none;
}

.ecg-chart-background-image {
  background-repeat: repeat;
  background-image: url(../images/graph_back.png);
}

#chart {
  width: 77.5rem;
  display: block;
}

.valRed {
  color: #ff3030;
}
.dx-freespace-row {
  height: 0px !important;
}
/* #popMeasure7 div::-webkit-scrollbar {
  width: 10px;
}
#popMeasure7 div::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
}
#popMeasure7 div::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}

#popMeasure7::-webkit-scrollbar {
  width: 10px;
}
#popMeasure7::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
}
#popMeasure7::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
} */
